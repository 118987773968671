.options {
    margin-bottom: 1px;
    width: 100%;
    padding: 4px;
  
    &:hover {
      background-color: #0053a0 !important;
      color: #fff;
      cursor: pointer;
    }
  
    &:first-of-type {
      height: 30px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  
    &:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      // border: none;
    } 
  }
  
  .active {
    color: "#fff";
    background: #0053a0;
  }
  
  .bottom {
    top: 110%;
  }
  
  .top {
    bottom: 110%;
  }