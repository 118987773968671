.heading-font{
    font-family: 'Ubuntu Condensed', sans-serif;
    font-weight: 600 !important;
}
h2{
     font-family: 'Ubuntu Condensed', sans-serif !important;
}

button{
     font-family: 'Ubuntu Condensed', sans-serif !important;
}


p{
     font-family:'Ubuntu', sans-serif !important;
}

.border-gradient {
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
}

.border-gradient-blue-left {
  border-image-source: linear-gradient(to left, #0071a4 48%
, #fff);
}

.border-gradient-blue-right {
  border-image-source: linear-gradient(to right, #0071a4 48%
, #fff);
}