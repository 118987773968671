:root{
    --black:#000000;
    --black-trans:rgba(0, 0, 0, 0.5);
    --blue:#0053a0;
    --gray-300:#F5F5F5;
    --gray-500:#D9D9D9;
    --blue-100:#F6F8FF;
    --white:#ffffff;
    --white-tans: rgba(255, 255, 255, 0.08);
    --green:#13AC19;
    --yellow:#388e3c;
    --gray:gray;

    --font-sm:14px;
    --font-md:16px;
    --font-lg:20px;
    --font-xlg:24px;

    --font-w-normal:500;
    --font-w-semi-bold:600;
    --font-w-bold:700;
}
*{
    // font-family: "Roboto, Sans-serif !important";
}
 